<template>
  <b-modal
    id="items-modal"
    ref="items-modal"
    no-close-on-backdrop
    hide-footer
    @show="setData"
    size="xl"
    dialog-class="items-modal position-relative"
    body-class="p-0"
    header-class="align-items-center p-2"
  >
    <template #modal-header="{ close }">
      <div class="d-flex">
        <h5 class="mb-0">รายการแทง <span class="text-danger">{{itemType | typeText}}</span> หมายเลข <span class="text-primary">{{numbersText}}</span></h5>
      </div>
      <b-button size="sm" variant="outline-danger" @click="close()">
        <i class="fas fa-times"></i>
      </b-button>
    </template>

    <div class="items-table">

      <div v-if="totalDoc > perPage" class="px-2 pt-2">
        <ol class="breadcrumb pl-2 pr-0 py-0 m-0 mb-2 border-info d-flex justify-content-between align-items-center">
          <li class="breadcrumb-item py-1">
            <span>แสดง {{ startRow | amountFormat(0) }} - {{ endRow | amountFormat(0) }} จาก {{ totalDoc | amountFormat(0) }} รายการ</span>
          </li>
          <li>
            <ul v-if="pages.length > 1" class="pagination justify-content-end mb-0">
              <li v-if="currentPage > 1" class="page-item" style="cursor: pointer; border-radius: 0;">
                <a class="page-link rounded-0" @click="togglePage(currentPage - 1)"><i class="fas fa-angle-left"></i></a>
              </li>
              <li v-if="currentPage > 3" class="page-item" style="cursor: pointer; border-radius: 0;">
                <a class="page-link rounded-0" @click="togglePage(1)">1</a>
              </li>
              <li v-if="currentPage > 4 && totalPage > 6" class="d-flex align-items-center p-1"><i class="fas fa-ellipsis-h"></i></li>

              <li
                v-for="(page, index) in pages"
                :key="index"
                class="page-item"
                :class="[{'active': page === currentPage}]"
                style="cursor: pointer; border-radius: 0;"
              ><a class="page-link rounded-0" @click="togglePage(page)">{{page}}</a></li>

              <li v-if="currentPage < (totalPage - 3) && totalPage > 6" class="d-flex align-items-center p-1"><i class="fas fa-ellipsis-h"></i></li>
              <li v-if="currentPage < (totalPage - 2)" class="page-item" style="cursor: pointer; border-radius: 0;">
                <a class="page-link rounded-0" @click="togglePage(totalPage)">{{totalPage}}</a>
              </li>
              <li v-if="currentPage < totalPage" class="page-item" style="cursor: pointer; border-radius: 0;">
                <a class="page-link rounded-0" @click="togglePage(currentPage + 1)"><i class="fas fa-angle-right"></i></a>
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <table class="table table-sm table-bordered mb-0 table-info">
        <thead>
          <tr>
            <th width="60" rowspan="2" class="text-center">#</th>
            <th width="150" rowspan="2" class="text-center">
              ชื่อใช้งาน
              <b-form-checkbox
                v-model="showName"
                :value="true"
                :unchecked-value="false"
                class="mt-2 font-weight-normal"
              >
                แสดงชื่อ
              </b-form-checkbox>
            </th>
            <th width="150" rowspan="2" class="text-center">วันที่</th>
            <th width="120" rowspan="2" class="text-center">ประเภท</th>
            <th width="100" rowspan="2" class="text-center">หมายเลข</th>
            <th width="100" rowspan="2" class="text-center">จำนวน</th>
            <th colspan="3" class="text-center border-left border-right">เมมเบอร์</th>
            <th colspan="3" class="text-center border-right">{{ agentUsername }}</th>
            <th colspan="3" class="text-center border-right">บริษัท</th>
            <th rowspan="2" class="text-center">หมายเหตุ</th>
          </tr>
          <tr>
            <th width="100" class="text-center border-left">ส่งออก</th>
            <th width="100" class="text-center">คอมฯ</th>
            <th width="100" class="text-center border-right">สุทธิ</th>

            <th width="100" class="text-center">ถือหุ้น</th>
            <th width="100" class="text-center">คอมฯ</th>
            <th width="100" class="text-center border-right">รวม</th>

            <th width="100" class="text-center">ถือหุ้น</th>
            <th width="100" class="text-center">คอมฯ</th>
            <th width="100" class="text-center border-right">รวม</th>
          </tr>
          <tr v-if="items.length > 10">
            <th colspan="5" class="text-center">รวม {{ dispSummary('totalDoc') | amountFormat(0) }} รายการ</th>
            <th class="text-right text-info">{{ dispSummary('amount') | amountFormat }}</th>
            <th
              class="text-right border-left"
              :class="[{
                'text-success': dispSummary('memberAmount')>0
              }, {
                'text-danger': dispSummary('memberAmount')<0
              }]"
            >{{ dispSummary('memberAmount') | amountFormat }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': dispSummary('memberCommission')>0
              }, {
                'text-danger': dispSummary('memberCommission')<0
              }]"
            >{{ dispSummary('memberCommission') | amountFormat }}</th>
            <th
              class="text-right border-right"
              :class="[{
                'text-success': dispSummary('memberTotal')>0
              }, {
                'text-danger': dispSummary('memberTotal')<0
              }]"
            >{{ dispSummary('memberTotal') | amountFormat }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': dispSummary('agentAmount')>0
              }, {
                'text-danger': dispSummary('agentAmount')<0
              }]"
            >{{ dispSummary('agentAmount') | amountFormat }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': dispSummary('agentCommission')>0
              }, {
                'text-danger': dispSummary('agentCommission')<0
              }]"
            >{{ dispSummary('agentCommission') | amountFormat }}</th>
            <th
              class="text-right border-right"
              :class="[{
                'text-success': dispSummary('agentTotal')>0
              }, {
                'text-danger': dispSummary('agentTotal')<0
              }]"
            >{{ dispSummary('agentTotal') | amountFormat }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': dispSummary('companyAmount')>0
              }, {
                'text-danger': dispSummary('companyAmount')<0
              }]"
            >{{ dispSummary('companyAmount') | amountFormat }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': dispSummary('companyCommission')>0
              }, {
                'text-danger': dispSummary('companyCommission')<0
              }]"
            >{{ dispSummary('companyCommission') | amountFormat }}</th>
            <th
              class="text-right border-right"
              :class="[{
                'text-success': dispSummary('companyTotal')>0
              }, {
                'text-danger': dispSummary('companyTotal')<0
              }]"
            >{{ dispSummary('companyTotal') | amountFormat }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items">
            <td class="text-center">{{offset+index+1}}</td>
            <td class="text-center">
              {{ item.account.mainUser.username }}
              <small v-if="showName" class="text-secondary">({{ item.account.mainUser.firstName }})</small>
            </td>
            <td class="text-center">{{ item.createdAt | dispDateTime('DD/MM/YY HH:mm') }}</td>
            <td class="text-center">{{ item.type | typeText }}</td>
            <td class="text-center text-primary">{{ item.number }}</td>
            <td class="text-right text-info">{{ item.amount | amountFormat }}</td>

            <td
              class="text-right border-left"
              :class="[{
                'text-success': item.refAmount.member.amount>0
              }, {
                'text-danger': item.refAmount.member.amount<0
              }]"
            >{{ item.refAmount.member.amount | amountFormat }}</td>
            <td
              class="text-right"
              :class="[{
                'text-success': item.refAmount.member.commission>0
              }, {
                'text-danger': item.refAmount.member.commission<0
              }]"
            >{{ item.refAmount.member.commission | amountFormat }}</td>
            <td
              class="text-right border-right"
              :class="[{
                'text-success': item.refAmount.member.total>0
              }, {
                'text-danger': item.refAmount.member.total<0
              }]"
            >{{ item.refAmount.member.total | amountFormat }}</td>

            <td
              class="text-right"
              :class="[{
                'text-success': item.refAmount.agent.amount>0
              }, {
                'text-danger': item.refAmount.agent.amount<0
              }]"
            >{{ item.refAmount.agent.amount | amountFormat }}</td>
            <td
              class="text-right"
              :class="[{
                'text-success': item.refAmount.agent.commission>0
              }, {
                'text-danger': item.refAmount.agent.commission<0
              }]"
            >{{ item.refAmount.agent.commission | amountFormat }}</td>
            <td
              class="text-right border-right"
              :class="[{
                'text-success': item.refAmount.agent.total>0
              }, {
                'text-danger': item.refAmount.agent.total<0
              }]"
            >{{ item.refAmount.agent.total | amountFormat }}</td>

            <td
              class="text-right"
              :class="[{
                'text-success': item.refAmount.company.amount>0
              }, {
                'text-danger': item.refAmount.company.amount<0
              }]"
            >{{ item.refAmount.company.amount | amountFormat }}</td>
            <td
              class="text-right"
              :class="[{
                'text-success': item.refAmount.company.commission>0
              }, {
                'text-danger': item.refAmount.company.commission<0
              }]"
            >{{ item.refAmount.company.commission | amountFormat }}</td>
            <td
              class="text-right border-right"
              :class="[{
                'text-success': item.refAmount.company.total>0
              }, {
                'text-danger': item.refAmount.company.total<0
              }]"
            >{{ item.refAmount.company.total | amountFormat }}</td>

            <td class="text-center">{{ item.remarks }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="5" class="text-center">รวม {{ dispSummary('totalDoc') | amountFormat(0) }} รายการ</th>
            <th class="text-right text-info">{{ dispSummary('amount') | amountFormat }}</th>
            <th
              class="text-right border-left"
              :class="[{
                'text-success': dispSummary('memberAmount')>0
              }, {
                'text-danger': dispSummary('memberAmount')<0
              }]"
            >{{ dispSummary('memberAmount') | amountFormat }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': dispSummary('memberCommission')>0
              }, {
                'text-danger': dispSummary('memberCommission')<0
              }]"
            >{{ dispSummary('memberCommission') | amountFormat }}</th>
            <th
              class="text-right border-right"
              :class="[{
                'text-success': dispSummary('memberTotal')>0
              }, {
                'text-danger': dispSummary('memberTotal')<0
              }]"
            >{{ dispSummary('memberTotal') | amountFormat }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': dispSummary('agentAmount')>0
              }, {
                'text-danger': dispSummary('agentAmount')<0
              }]"
            >{{ dispSummary('agentAmount') | amountFormat }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': dispSummary('agentCommission')>0
              }, {
                'text-danger': dispSummary('agentCommission')<0
              }]"
            >{{ dispSummary('agentCommission') | amountFormat }}</th>
            <th
              class="text-right border-right"
              :class="[{
                'text-success': dispSummary('agentTotal')>0
              }, {
                'text-danger': dispSummary('agentTotal')<0
              }]"
            >{{ dispSummary('agentTotal') | amountFormat }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': dispSummary('companyAmount')>0
              }, {
                'text-danger': dispSummary('companyAmount')<0
              }]"
            >{{ dispSummary('companyAmount') | amountFormat }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': dispSummary('companyCommission')>0
              }, {
                'text-danger': dispSummary('companyCommission')<0
              }]"
            >{{ dispSummary('companyCommission') | amountFormat }}</th>
            <th
              class="text-right border-right"
              :class="[{
                'text-success': dispSummary('companyTotal')>0
              }, {
                'text-danger': dispSummary('companyTotal')<0
              }]"
            >{{ dispSummary('companyTotal') | amountFormat }}</th>
            <th></th>
          </tr>
        </tfoot>
      </table>

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </div>
  </b-modal>
</template>
<script>
import ReportService from "@/services/ReportService"
import cAlert from '@/helpers/alert'

export default {
  name: 'ViewItemsModal',
  props: ['isShow', 'roundId', 'itemType', 'itemNumbers'],
  data() {
    return {
      isLoading: false,
      showName: false,
      data: null,
      account: null,
      summary: null,
      perPage: 1000,
      currentPage: 1
    }
  },
  computed: {
    numbersText() {
      return this.itemNumbers.join(', ')
    },
    items() {
      if(!this.data)
        return []

      return this.data
    },
    agentUsername() {
      return this.account?.username
    },
    totalDoc() {
      return this.summary?.totalDoc || 0
    },
    totalPage() {
      return Math.ceil(this.totalDoc/this.perPage)
    },
    pages() {
      let prevPage = this.currentPage - 2
      let nextPage = this.currentPage + 2

      if(prevPage < 1) {
        nextPage = nextPage - prevPage + 1
      }else
      if(nextPage >= this.totalPage) {
        prevPage = prevPage - (nextPage - this.totalPage)
      }

      const startPage = prevPage < 1 ? 1 : prevPage
      const endPage = nextPage > this.totalPage ? this.totalPage : nextPage

      const pages = []
      for(let i=startPage; i<=endPage; i++) {
        pages.push(i)
      }

      return pages
    },
    offset() {
      return (this.currentPage * this.perPage) - this.perPage
    },
    startRow() {
      return this.offset + 1
    },
    endRow() {
      const max = this.startRow + this.perPage - 1
      return max < this.totalDoc ? max : this.totalDoc
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.$refs['items-modal'].show()
      }else{
        this.$refs['items-modal'].hide()
      }
    }
  },
  methods: {
    loadItems() {
      if(!this.roundId)
        return

      this.isLoading = true
      ReportService.getItems(this.roundId, {
        type: this.itemType,
        numbers: this.itemNumbers
      }, this.offset)
      .then((response)=>{
        if(response.success) {
          this.account = response.data.account
          this.summary = response.data.summary
          this.data = response.data.items.map((item)=>{
            item.refAmount = item.refsAmount[this.account.levelId]
            return item
          })
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        this.$refs['items-modal'].hide()
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'ดึงรายการไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    dispSummary(col) {
      if(!this.summary)
        return 0

      return this.summary?.[col] || 0
    },
    togglePage(page) {
      this.currentPage = page
      this.loadItems()
    },
    setData() {
      this.currentPage = 1
      this.loadItems()
    }
  },
  filters: {
    typeText(type) {
      return {
        threeNumberTop: '3 ตัวบน',
        threeNumberTode: '3 ตัวโต๊ด',
        twoNumberTop: '2 ตัวบน',
        twoNumberBottom: '2 ตัวล่าง',
        runTop: 'วิ่งบน',
        runBottom: 'วิ่งล่าง'
      }[type]
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
<style lang="scss">
#items-modal {
  .items-modal {
    // width: 90% !important;
    max-width: 90%;
  }
  .items-table {
    min-height: 100px;

    .border-right {
      border-right: 2px solid #86cfda !important;
    }

    .border-left {
      border-left: 2px solid #86cfda !important;
    }

    table {
      thead {
        tr {
          th {
            // font-weight: normal;
            font-size: 85%;
            line-height: 1.1;
          }
        }
      }
      tbody {
        background-color: #FFF;

        tr {
          td {
            vertical-align: middle;
            padding: 4px;
            font-size: 90%;
          }
        }
      }
      tfoot {
        tr {
          th, td {
            font-size: 90%;
          }
        }
      }
    }
  }
}
</style>
