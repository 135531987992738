<template>
  <div id="round-winlose-all" class="p-2">

    <div class="my-2 form-inline">

      <b-input-group size="sm" prepend="แสดง">
        <b-form-select v-model="showData">
          <b-form-select-option value="overall">ถือหุ้น</b-form-select-option>
          <b-form-select-option value="profit">คาดคะเน ได้-เสีย</b-form-select-option>
        </b-form-select>
      </b-input-group>

      <b-input-group size="sm" prepend="เรียงลำดับ" class="ml-1">
        <b-form-select v-model="sortBy">
          <b-form-select-option value="number">หมายเลข</b-form-select-option>
          <b-form-select-option value="lose">คาดคะเน ยอดเสีย</b-form-select-option>
          <b-form-select-option value="overall">ถือหุ้น</b-form-select-option>
        </b-form-select>
      </b-input-group>

      <b-input-group size="sm" prepend="เรียงจาก" class="ml-1">
        <b-form-select v-model="sortOrder">
          <b-form-select-option value="asc">น้อย > มาก</b-form-select-option>
          <b-form-select-option value="desc">มาก > น้อย</b-form-select-option>
        </b-form-select>
      </b-input-group>

      <b-input-group size="sm" prepend="จำนวนแถว" class="ml-1">
        <b-form-select v-model="rowLength">
          <b-form-select-option :value="10">10</b-form-select-option>
          <b-form-select-option :value="50">50</b-form-select-option>
          <b-form-select-option :value="100">100</b-form-select-option>
          <b-form-select-option :value="250">250</b-form-select-option>
          <b-form-select-option :value="500">500</b-form-select-option>
          <b-form-select-option :value="750">750</b-form-select-option>
          <b-form-select-option :value="1000">1000</b-form-select-option>
        </b-form-select>
      </b-input-group>

    </div>

    <div class="mb-2">
      <small class="alert-primary rounded px-1 border-primary">พื้นหลังสีฟ้า = <strong>เต็มแล้ว</strong></small>
      <small class="alert-warning rounded px-1 border-warning ml-2">พื้นหลังสีเหลือง = <strong>ถูกรางวัล</strong></small>
      <small class="alert-secondary rounded px-1 border-secondary ml-2">กด Ctrl+F เพื่อค้นหา</small>
    </div>

    <table class="table table-secondary table-sm table-bordered table-hover mb-0">
      <thead>
        <tr>
          <th width="16%">รวม</th>
          <th width="14%">3 ตัวบน</th>
          <th width="14%">3 ตัวโต๊ด</th>
          <th width="14%">2 ตัวบน</th>
          <th width="14%">2 ตัวล่าง</th>
          <th width="14%">วิ่งบน</th>
          <th width="14%">วิ่งล่าง</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>
            <div class="d-flex justify-content-between align-items-center">
              <span>ซื้อ</span>
              <span class="text-primary">{{ total.overall.amount | amountFormat(2, '0.00') }}</span>
            </div>
          </th>
          <td class="text-primary text-right">{{ total.threeNumberTop.amount | amountFormat(2, '0.00') }}</td>
          <td class="text-primary text-right">{{ total.threeNumberTode.amount | amountFormat(2, '0.00') }}</td>
          <td class="text-primary text-right">{{ total.twoNumberTop.amount | amountFormat(2, '0.00') }}</td>
          <td class="text-primary text-right">{{ total.twoNumberBottom.amount | amountFormat(2, '0.00') }}</td>
          <td class="text-primary text-right">{{ total.runTop.amount | amountFormat(2, '0.00') }}</td>
          <td class="text-primary text-right">{{ total.runBottom.amount | amountFormat(2, '0.00') }}</td>
        </tr>
        <tr>
          <th>
            <div class="d-flex justify-content-between align-items-center">
              <span>คอมฯ</span>
              <span class="text-danger">{{ total.overall.comission | amountFormat(2, '0.00') }}</span>
            </div>
          </th>
          <td class="text-danger text-right">{{ total.threeNumberTop.comission | amountFormat(2, '0.00') }}</td>
          <td class="text-danger text-right">{{ total.threeNumberTode.comission | amountFormat(2, '0.00') }}</td>
          <td class="text-danger text-right">{{ total.twoNumberTop.comission | amountFormat(2, '0.00') }}</td>
          <td class="text-danger text-right">{{ total.twoNumberBottom.comission | amountFormat(2, '0.00') }}</td>
          <td class="text-danger text-right">{{ total.runTop.comission | amountFormat(2, '0.00') }}</td>
          <td class="text-danger text-right">{{ total.runBottom.comission | amountFormat(2, '0.00') }}</td>
        </tr>
        <tr>
          <th>
            <div class="d-flex justify-content-between align-items-center">
              <span>รับ</span>
              <span class="text-success">{{ total.overall.total | amountFormat(2, '0.00') }}</span>
            </div>
          </th>
          <td class="text-success text-right">{{ total.threeNumberTop.total | amountFormat(2, '0.00') }}</td>
          <td class="text-success text-right">{{ total.threeNumberTode.total | amountFormat(2, '0.00') }}</td>
          <td class="text-success text-right">{{ total.twoNumberTop.total | amountFormat(2, '0.00') }}</td>
          <td class="text-success text-right">{{ total.twoNumberBottom.total | amountFormat(2, '0.00') }}</td>
          <td class="text-success text-right">{{ total.runTop.total | amountFormat(2, '0.00') }}</td>
          <td class="text-success text-right">{{ total.runBottom.total | amountFormat(2, '0.00') }}</td>
        </tr>
        <tr>
          <th>
            <div class="d-flex justify-content-between align-items-center">
              <span>จ่าย</span>
              <span class="text-danger">{{ min.overall | amountFormat(2, '0.00') }}</span>
            </div>
          </th>
          <td class="text-right" :class="[{'text-danger': min.threeNumberTop<0}, {'text-success': min.threeNumberTop>0}]">{{ min.threeNumberTop | amountFormat(2, '0.00') }}</td>
          <td class="text-right" :class="[{'text-danger': min.threeNumberTode<0}, {'text-success': min.threeNumberTode>0}]">{{ min.threeNumberTode | amountFormat(2, '0.00') }}</td>
          <td class="text-right" :class="[{'text-danger': min.twoNumberTop<0}, {'text-success': min.twoNumberTop>0}]">{{ min.twoNumberTop | amountFormat(2, '0.00') }}</td>
          <td class="text-right" :class="[{'text-danger': min.twoNumberBottom<0}, {'text-success': min.twoNumberBottom>0}]">{{ min.twoNumberBottom | amountFormat(2, '0.00') }}</td>
          <td class="text-right" :class="[{'text-danger': min.runTop<0}, {'text-success': min.runTop>0}]">{{ min.runTop | amountFormat(2, '0.00') }}</td>
          <td class="text-right" :class="[{'text-danger': min.runBottom<0}, {'text-success': min.runBottom>0}]">{{ min.runBottom | amountFormat(2, '0.00') }}</td>
        </tr>
        <tr>
          <th>
            <div class="d-flex justify-content-between align-items-center">
              <span>ตั้งสู้</span>
              <button :disabled="isProcess" class="btn btn-success btn-sm" @click="saveReceive">บันทึก</button>
            </div>
          </th>
          <td>
            <b-form-input size="sm" v-model="setReceive.threeNumberTop" :number="true" v-on:keydown="keynumber" class="text-center"></b-form-input>
          </td>
          <td>
            <b-form-input size="sm" v-model="setReceive.threeNumberTode" :number="true" v-on:keydown="keynumber" class="text-center"></b-form-input>
          </td>
          <td>
            <b-form-input size="sm" v-model="setReceive.twoNumberTop" :number="true" v-on:keydown="keynumber" class="text-center"></b-form-input>
          </td>
          <td>
            <b-form-input size="sm" v-model="setReceive.twoNumberBottom" :number="true" v-on:keydown="keynumber" class="text-center"></b-form-input>
          </td>
          <td>
            <b-form-input size="sm" v-model="setReceive.runTop" :number="true" v-on:keydown="keynumber" class="text-center"></b-form-input>
          </td>
          <td>
            <b-form-input size="sm" v-model="setReceive.runBottom" :number="true" v-on:keydown="keynumber" class="text-center"></b-form-input>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-center">{{index + 1}}</td>
          <td :class="[
            {'alert-primary': agentAmount(item, 'threeNumberTop') >= setReceive.threeNumberTop},
            {'alert-warning': isWon(item, 'threeNumberTop')}
          ]">
            <div v-if="item.threeNumberTop" class="d-flex justify-content-between align-items-center">
              <span class="text-primary click-report" @click="viewItem(item, 'threeNumberTop')">{{ item.threeNumberTop._id.number }}</span>
              <span v-if="showData==='profit'" :class="[{'text-danger': item.threeNumberTop.winlose<0}, {'text-success': item.threeNumberTop.winlose>0}]">{{ item.threeNumberTop.winlose | amountFormat(2, '0.00') }}</span>
              <span v-if="showData==='overall'" class="text-info">{{ item.threeNumberTop.agentAmount | amountFormat(2, '0.00') }}</span>
            </div>
          </td>
          <td :class="[
            {'alert-primary': agentAmount(item, 'threeNumberTode') >= setReceive.threeNumberTode},
            {'alert-warning': isWon(item, 'threeNumberTode')}
          ]">
            <div v-if="item.threeNumberTode" class="d-flex justify-content-between align-items-center">
              <span class="text-primary click-report" @click="viewItem(item, 'threeNumberTode')">{{ item.threeNumberTode._id.number }}</span>
              <span v-if="showData==='profit'" :class="[{'text-danger': item.threeNumberTode.winlose<0}, {'text-success': item.threeNumberTode.winlose>0}]">{{ item.threeNumberTode.winlose | amountFormat(2, '0.00') }}</span>
              <span v-if="showData==='overall'" class="text-info">{{ item.threeNumberTode.agentAmount | amountFormat(2, '0.00') }}</span>
            </div>
          </td>
          <td :class="[
            {'alert-primary': agentAmount(item, 'twoNumberTop') >= setReceive.twoNumberTop},
            {'alert-warning': isWon(item, 'twoNumberTop')}
          ]">
            <div v-if="item.twoNumberTop" class="d-flex justify-content-between align-items-center">
              <span class="text-primary click-report" @click="viewItem(item, 'twoNumberTop')">{{ item.twoNumberTop._id.number }}</span>
              <span v-if="showData==='profit'" :class="[{'text-danger': item.twoNumberTop.winlose<0}, {'text-success': item.twoNumberTop.winlose>0}]">{{ item.twoNumberTop.winlose | amountFormat(2, '0.00') }}</span>
              <span v-if="showData==='overall'" class="text-info">{{ item.twoNumberTop.agentAmount | amountFormat(2, '0.00') }}</span>
            </div>
          </td>
          <td :class="[
            {'alert-primary': agentAmount(item, 'twoNumberBottom') >= setReceive.twoNumberBottom},
            {'alert-warning': isWon(item, 'twoNumberBottom')}
          ]">
            <div v-if="item.twoNumberBottom" class="d-flex justify-content-between align-items-center">
              <span class="text-primary click-report" @click="viewItem(item, 'twoNumberBottom')">{{ item.twoNumberBottom._id.number }}</span>
              <span v-if="showData==='profit'" :class="[{'text-danger': item.twoNumberBottom.winlose<0}, {'text-success': item.twoNumberBottom.winlose>0}]">{{ item.twoNumberBottom.winlose | amountFormat(2, '0.00') }}</span>
              <span v-if="showData==='overall'" class="text-info">{{ item.twoNumberBottom.agentAmount | amountFormat(2, '0.00') }}</span>
            </div>
          </td>
          <td :class="[
            {'alert-primary': agentAmount(item, 'runTop') >= setReceive.runTop},
            {'alert-warning': isWon(item, 'runTop')}
          ]">
            <div v-if="item.runTop" class="d-flex justify-content-between align-items-center">
              <span class="text-primary click-report" @click="viewItem(item, 'runTop')">{{ item.runTop._id.number }}</span>
              <span v-if="showData==='profit'" :class="[{'text-danger': item.runTop.winlose<0}, {'text-success': item.runTop.winlose>0}]">{{ item.runTop.winlose | amountFormat(2, '0.00') }}</span>
              <span v-if="showData==='overall'" class="text-info">{{ item.runTop.agentAmount | amountFormat(2, '0.00') }}</span>
            </div>
          </td>
          <td :class="[
            {'alert-primary': agentAmount(item, 'runBottom') >= setReceive.runBottom},
            {'alert-warning': isWon(item, 'runBottom')}
          ]">
            <div v-if="item.runBottom" class="d-flex justify-content-between align-items-center">
              <span class="text-primary click-report" @click="viewItem(item, 'runBottom')">{{ item.runBottom._id.number }}</span>
              <span v-if="showData==='profit'" :class="[{'text-danger': item.runBottom.winlose<0}, {'text-success': item.runBottom.winlose>0}]">{{ item.runBottom.winlose | amountFormat(2, '0.00') }}</span>
              <span v-if="showData==='overall'" class="text-info">{{ item.runBottom.agentAmount | amountFormat(2, '0.00') }}</span>
            </div>
          </td>
        </tr>
        <tr v-if="!items.length && !isLoading">
          <td colspan="7" class="text-center alert-warning">ไม่มียอดแทง</td>
        </tr>
        <tr v-if="!items.length && isLoading">
          <td colspan="7" class="text-center alert-info">กำลังโหลดข้อมูล...</td>
        </tr>
      </tbody>
    </table>

    <ViewItemsModal
      :isShow="isShowModal"
      :roundId="roundId"
      :itemType="itemType"
      :itemNumbers="itemNumbers"
      @close="closeModal"
    />
  </div>
</template>
<script>
import _ from 'lodash'
import RoundService from "@/services/RoundService"

import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import cAlert from '@/helpers/alert'
import ViewItemsModal from './ViewItemsModal'

export default {
  name: 'RoundWinLoseAll',
  props: ['roundData', 'setReceive', 'data', 'total', 'isLoading'],
  components: {
    ViewItemsModal
  },
  data() {
    return {
      rowLength: 50,
      sortBy: 'lose',
      sortOrder: 'desc',
      findNumbers: '',
      showData: 'profit',
      isProcess: false,
      isShowModal: false,
      itemType: null,
      itemNumbers: []
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    numberItems() {

      const threeNumberTop = _.chunk(_.sortBy(this.data.threeNumberTop, (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item._id.number) : -parseInt(item._id.number),
          overall: this.sortOrder==='asc' ? item.agentAmount : -item.agentAmount,
          lose: this.sortOrder==='asc' ? -item.winlose : item.winlose
        }[this.sortBy]
      }), this.rowLength)

      const threeNumberTode = _.chunk(_.sortBy(this.data.threeNumberTode, (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item._id.number) : -parseInt(item._id.number),
          overall: this.sortOrder==='asc' ? item.agentAmount : -item.agentAmount,
          lose: this.sortOrder==='asc' ? -item.winlose : item.winlose
        }[this.sortBy]
      }), this.rowLength)

      const twoNumberTop = _.chunk(_.sortBy(this.data.twoNumberTop, (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item._id.number) : -parseInt(item._id.number),
          overall: this.sortOrder==='asc' ? item.agentAmount : -item.agentAmount,
          lose: this.sortOrder==='asc' ? -item.winlose : item.winlose
        }[this.sortBy]
      }), this.rowLength)

      const twoNumberBottom = _.chunk(_.sortBy(this.data.twoNumberBottom, (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item._id.number) : -parseInt(item._id.number),
          overall: this.sortOrder==='asc' ? item.agentAmount : -item.agentAmount,
          lose: this.sortOrder==='asc' ? -item.winlose : item.winlose
        }[this.sortBy]
      }), this.rowLength)

      const runTop = _.chunk(_.sortBy(this.data.runTop, (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item._id.number) : -parseInt(item._id.number),
          overall: this.sortOrder==='asc' ? item.agentAmount : -item.agentAmount,
          lose: this.sortOrder==='asc' ? -item.winlose : item.winlose
        }[this.sortBy]
      }), this.rowLength)

      const runBottom = _.chunk(_.sortBy(this.data.runBottom, (item)=>{
        return {
          number: this.sortOrder==='asc' ? parseInt(item._id.number) : -parseInt(item._id.number),
          overall: this.sortOrder==='asc' ? item.agentAmount : -item.agentAmount,
          lose: this.sortOrder==='asc' ? -item.winlose : item.winlose
        }[this.sortBy]
      }), this.rowLength)

      return {
        threeNumberTop: threeNumberTop[0],
        threeNumberTode: threeNumberTode[0],
        twoNumberTop: twoNumberTop[0],
        twoNumberBottom: twoNumberBottom[0],
        runTop: runTop[0],
        runBottom: runBottom[0]
      }
    },
    maxLength() {
      return _.maxBy([{
        key: 'threeNumberTop',
        length: this.numberItems.threeNumberTop?.length || 0
      }, {
        key: 'threeNumberTode',
        length: this.numberItems.threeNumberTode?.length || 0
      }, {
        key: 'twoNumberTop',
        length: this.numberItems.twoNumberTop?.length || 0
      }, {
        key: 'twoNumberBottom',
        length: this.numberItems.twoNumberBottom?.length || 0
      }, {
        key: 'runTop',
        length: this.numberItems.runTop?.length || 0
      }, {
        key: 'runBottom',
        length: this.numberItems.runBottom?.length || 0
      }], (item)=>{
        return item.length
      })
    },
    items() {
      const items = []
      for(let i=0; i<this.maxLength.length; i++) {
        items.push({
          threeNumberTop: this.numberItems.threeNumberTop?.[i] || null,
          threeNumberTode: this.numberItems.threeNumberTode?.[i] || null,
          twoNumberTop: this.numberItems.twoNumberTop?.[i] || null,
          twoNumberBottom: this.numberItems.twoNumberBottom?.[i] || null,
          runTop: this.numberItems.runTop?.[i] || null,
          runBottom: this.numberItems.runBottom?.[i] || null,
        })
      }
      return items
    },
    max() {
      const threeNumberTop = _.maxBy(this.data.threeNumberTop, (item)=>{
        return item.reward
      })

      const threeNumberTode = _.maxBy(this.data.threeNumberTode, (item)=>{
        return item.reward
      })

      const twoNumberTop = _.maxBy(this.data.twoNumberTop, (item)=>{
        return item.reward
      })

      const twoNumberBottom = _.maxBy(this.data.twoNumberBottom, (item)=>{
        return item.reward
      })

      const runTop = _.maxBy(this.data.runTop, (item)=>{
        return item.reward
      })

      const runBottom = _.maxBy(this.data.runBottom, (item)=>{
        return item.reward
      })

      return {
        threeNumberTop: threeNumberTop?.reward || 0,
        threeNumberTode: threeNumberTode?.reward || 0,
        twoNumberTop: twoNumberTop?.reward || 0,
        twoNumberBottom: twoNumberBottom?.reward || 0,
        runTop: runTop?.reward || 0,
        runBottom: runBottom?.reward || 0,
        overall: (threeNumberTop?.reward || 0) + (threeNumberTode?.reward || 0) + (twoNumberTop?.reward || 0) + (twoNumberBottom?.reward) || (runTop?.reward || 0) + (runBottom?.reward || 0)
      }
    },
    min() {
      const threeNumberTop = _.minBy(this.data.threeNumberTop, (item)=>{
        return item.winlose
      })

      const threeNumberTode = _.minBy(this.data.threeNumberTode, (item)=>{
        return item.winlose
      })

      const twoNumberTop = _.minBy(this.data.twoNumberTop, (item)=>{
        return item.winlose
      })

      const twoNumberBottom = _.minBy(this.data.twoNumberBottom, (item)=>{
        return item.winlose
      })

      const runTop = _.minBy(this.data.runTop, (item)=>{
        return item.winlose
      })

      const runBottom = _.minBy(this.data.runBottom, (item)=>{
        return item.winlose
      })

      return {
        threeNumberTop: threeNumberTop?.winlose || 0,
        threeNumberTode: threeNumberTode?.winlose || 0,
        twoNumberTop: twoNumberTop?.winlose || 0,
        twoNumberBottom: twoNumberBottom?.winlose || 0,
        runTop: runTop?.winlose || 0,
        runBottom: runBottom?.winlose || 0,
        overall: (threeNumberTop?.winlose || 0) + (threeNumberTode?.winlose || 0) + (twoNumberTop?.winlose || 0) + (twoNumberBottom?.winlose) || (runTop?.winlose || 0) + (runBottom?.winlose || 0)
      }
    }
  },
  methods: {
    keynumber(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab'].includes(event.key)) {
        event.preventDefault()
      }
    },
    agentAmount(item, type) {
      if(type === 'threeNumberTode') {

        if(!item.threeNumberTode?._id?.number)
          return 0

        /**
         * 3 ตัวโต๊ดคำนวนจากกลุ่มโต๊ด
         */
        const todeNumbers = this.gThreeNumberTode(item.threeNumberTode._id.number)
        return this.data.threeNumberTode.filter((it)=>{
          return todeNumbers.includes(it._id.number)
        })
        .reduce((total, it)=>{
          total += it.agentAmount
          return total
        }, 0)
      }else{
        return item?.[type]?.agentAmount || 0
      }
    },
    isWon(item, type) {
      return (this.roundData?.round?.results?.[type] || []).includes(item?.[type]?._id?.number)
    },
    saveReceive() {
      this.isProcess = true
      RoundService.updateRoundReceive({
        roundId: this.roundId,
        receive: this.setReceive
      })
      .then((response)=>{
        if(response.success) {
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
          this.$emit('reload')
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    gThreeNumberTode(number) {
      if(!number)
        return []

      const n = number.slice('')
      const numbers = [
        `${n[0]}${n[1]}${n[2]}`,
        `${n[0]}${n[2]}${n[1]}`,
        `${n[1]}${n[0]}${n[2]}`,
        `${n[1]}${n[2]}${n[0]}`,
        `${n[2]}${n[1]}${n[0]}`,
        `${n[2]}${n[0]}${n[1]}`,
      ]

      return _.uniqBy(numbers)
    },
    viewItem(item, type) {
      const number = item?.[type]._id.number
      if(!number)
        return

      if(type === 'threeNumberTode') {
        this.itemNumbers = this.gThreeNumberTode(number)
      }else{
        this.itemNumbers = [number]
      }

      this.itemType = type
      this.isShowModal = true
    },
    closeModal() {
      this.itemType = null
      this.itemNumbers = []
      this.isShowModal = false
    }
  }
}
</script>
<style lang="scss" scoped>
#round-winlose-all {
  .table {
    font-size: 90%;

    thead {
      tr {
        th, td {
          text-align: center;
          vertical-align: middle;
        }
      }
    }
    tbody {
      background-color: #FFF;
    }

    th, td {
      vertical-align: middle;

      &.border-right {
        border-right: 3px solid #86cfda !important;
      }
      &.border-top {
        border-top: 3px solid #86cfda !important;
      }

      .click-report {
        cursor: pointer;
      }

      .click-report:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
